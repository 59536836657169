import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Roswell GA Web Developer - BCK Case Study - Websuasion"
        ogDescription="Are you looking for a professional web developer serving Roswell, GA? See how we enabled BCK Consulting to achieve their goals."
        image="/images/case-studies/roswell-ga-web-developer-bck-case-study.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Roswell GA Web Developer</h1>
						<h3>A Case Study of BCK Consulting</h3>
            <p>
							<span className="websuasion">websuasion</span> enabled BCK Consulting to achieve their web development and web presence goals by providing website design, web portal development, photography, logo design, webinar video production, and ongoing support.
            </p>
						<p>
							Are you looking for a professional web developer serving the Roswell, GA area to help transform your business? Let's schedule an introductory call.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
        textRight={
          <div>
					<img src="/images/case-studies/roswell-ga-web-developer-bck-case-study.jpg" className="rounded-image" alt="Roswell GA Web Developer - Websuasion" />
          </div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								<img src="/images/case-studies/bck-consulting-logo.webp" alt="Roswell GA Web Developer - Websuasion" />
							</p>
			        <p>
								BCK Consulting uses the science of psychology to produce dramatic, measurable business results. They assist domestic and global partners on issues of selection, individual and team performance, and people systems, providing effective consultation and expert advice on a myriad of business challenges and people performance issues.
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="violet">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Client Testimonial</h2>
	            <Blockquote>Great group to work with for our website design. Listened to our ideas and spent a good amount of time really trying to understand what we do... which is sometimes a bit confusing! Highly impressed with final product and we look forward to a long-term relationship with websuasion.</Blockquote>
							<p>
								- Courtney Rabin, Staff Consultant
							</p>
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/case-studies/bck-consulting-courtney-rabin.jpg" className="rounded-square" alt="Roswell GA Web Developer - Websuasion" width="300" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h2>Client Needs</h2>
						<ul>
						 	<li>Website</li>
						 	<li>Web portal for psychological testing</li>
						 	<li>Marketing content</li>
						 	<li>Photography</li>
						 	<li>Logo for web portal</li>
						 	<li>Webinar videos</li>
						</ul>
          </div>
        }
        textRight={
          <div>
						<h2>websuasion Solutions</h2>
						<ul>
						 	<li>Desktop, tablet, and mobile responsive layout</li>
						 	<li>Web portal with robust functionality for psychological testing</li>
						 	<li>Marketing copy crafted towards businesses without being technical</li>
						 	<li>Photo session highlighting team</li>
						 	<li>Clean, simple logo</li>
						 	<li>Webinar video production</li>
						</ul>
          </div>
        }
      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Marketing Website Design</h2>
							<p>
								BCK hired us in 2015 to design and build a responsive website that would not only highlight their consulting services, but also reflect the historical roots of their location in Roswell Georgia. Our work included extensive development of the site content. We were challenged to refine a large amount of highly-technical, psychological content down to an easily digestible outline of BCK's consulting services which spoke directly to the needs of their clientele.
							</p>
							<p>
			          <ReactPlayer
			            url= '/files/case-studies/bck-site.mp4'
			            width='100%'
			            height='100%'
			            controls = {true}
			          />
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Web Portal</h2>
							<p>
								In 2016, BCK added a new service called CLEAR Screen. We were contracted to build a web portal that would allow BCK to provide psychological evaluations to their clients' prospective employees. The evaluation results were then to be made available to the clients via email notification allowing their clientele control over hiring status. Ease of use and an intuitive layout was a top priority. The resulting portal gives BCK everything they required, including detailed reporting functionality, user management, results upload, and the ability to send out testing invites to prospects. After a successful first phase, the portal is now being expanded to support customized branding for their clients.
							</p>
							<p>
			          <ReactPlayer
			            url= '/files/case-studies/clearscreen.mp4'
			            width='100%'
			            height='100%'
			            controls = {true}
			          />
							</p>
							<h2>Digital Transformation</h2>
							<p>
								In late 2018, we were hired to expand the CLEAR Screen system. We transitioned BCK from their spreadsheet-based reporting system to an automated system. It took raw test data and ran the hundreds of algorithms necessary to generate specialized HR hiring reports. The automated system was launched in early 2019 after extensive quality assurance testing.
	          	</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Logo Design</h2>
							<p>
								With the addition of the web portal, we were asked to create a logo for CLEAR Screen. After several drafts, BCK agreed to a magnifying glass concept which was further revised -- culminating in the final clean and simple design which translates well at any size.
							</p>
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/case-studies/bck-clear-screen-logo.webp" alt="Roswell GA Web Developer - Websuasion" width="300" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Long Format Video and Evergreen Webinar Content</h2>
							<p>
								In early 2019, we began production on videos for use with live and evergreen webinars for BCK's promotion of CLEAR Screen. Talking head and interview segments were recorded in our video facility. The shoots included multi-point lighting, high-resolution audio and 4K video against seamless paper backdrops. The footage was edited and output for 1080p streaming.							</p>
							<p>
			          <ReactPlayer
			            url= 'https://www.youtube.com/watch?v=89L_xTNdUKc'
			          />
							</p>
						</div>
	        }
	      />
			</PageWrapper>
					
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Photography</h2>
							<p>
								We provided photography of BCK's principle staff for use with their website and social media. We shot on-location in Historic Roswell, Georgia where BCK's offices are based.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Ongoing Support</h2>
							<p>On a continual basis, we provide content maintenance, data management, technical support, and hosting of the existing site and web portal.</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
